<template>
  <div class="customer_response">
    <div class="order_top">
      <div class="storeCenter_companyinfo_top_left">
        <img v-if="companyinfo.companyLogo" :src="companyinfo.companyLogo" alt="" />
        <img v-else src="../../assets/image/Group 3237.png" alt="" />
        <div class="storeCenter_companyinfo_top_left_flex">
          <div style="display: flex; align-companyinfos: center">
            <h5 style="display: inline-block; margin: 0; font-size: 20px">
              {{ companyinfo.companyFullName }}
            </h5>
            <div class="tag_item">
              <div
                class="tag_item_div"
                :class="countClass(tt)"
                v-for="(tt, ii) in companyinfo.state"
                :key="ii"
              >
                {{ tt }}
              </div>
            </div>
          </div>
          <div class="address">
            <span>所在地区： {{ companyinfo.province }}-{{ companyinfo.city }}</span>
          </div>
        </div>
      </div>
      <div class="buttom_info">
        <el-button
          style="color: #146aff"
          @click="goRouter"
          class="primary_buttom"
          type="primary"
          plain
          >返回上级</el-button
        >
      </div>
    </div>
    <div class="form_info">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in typeList"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === indexType ? 'active' : ''"
        >
          <span>{{ item.name }} ({{ item.num }})</span>
        </div>
      </div>
      <div class="form_title">
        <div class="search_left">
          <el-input
            :placeholder="placeholderCode"
            v-model="queryInfo.keyWord"
            class="input-with-select"
            style="width: 500px"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <div class="form_title_right">
          <el-button @click="addExpert" type="primary" v-if="indexType === 2">新增专家</el-button>
          <el-button @click="addConsultant" type="primary" v-if="indexType === 3"
            >新增顾问</el-button
          >
        </div>
      </div>
      <div class="tab_txt">
        <template v-if="indexType === 1">
          <el-table
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="编号" align="center" type="index" width="50"></el-table-column>
            <el-table-column label="昵称" prop="nickName" align="center"></el-table-column>
            <el-table-column label="回复内容" prop="reply" align="center"></el-table-column>
            <el-table-column
              sortable
              label="回复时间"
              prop="replyTime"
              align="center"
            ></el-table-column>
          </el-table>
        </template>
        <template v-else-if="indexType === 2">
          <el-table
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="编号" type="index" width="50" align="center"></el-table-column>
            <el-table-column
              label="专家姓名"
              prop="specialistName"
              align="center"
            ></el-table-column>
            <el-table-column
              label="所属企业"
              prop="companyFullName"
              align="center"
            ></el-table-column>
            <el-table-column label="对接人职位" prop="position" align="center"></el-table-column>
            <el-table-column label="联系电话" prop="contactPhone" align="center"></el-table-column>
            <el-table-column label="新增人" prop="name" align="center"></el-table-column>
            <el-table-column
              sortable
              label="回复时间"
              prop="updateTime"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" width="100">
              <template v-slot="{ row }">
                <div class="btn">
                  <span class="pass" @click="editClick(row)" style="margin-right: 10px">编辑</span>
                  <span v-if="row.notFreeze" class="frozen" @click="delcompanyZh(item)">冻结</span>
                  <span v-else class="frozen" @click="delcompanyZh(item)">激活</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else-if="indexType === 3">
          <el-table
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="编号" type="index" width="50" align="center"></el-table-column>
            <el-table-column label="顾问姓名" prop="realName" align="center"></el-table-column>
            <el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
            <el-table-column label="新增人" prop="insertName" align="center"></el-table-column>
            <el-table-column
              sortable
              label="新增时间"
              prop="updateTime"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" width="100">
              <template v-slot="{ row }">
                <div class="btn">
                  <span v-if="row.notFreeze" class="frozen" @click="delcompanyGw(item)">冻结</span>
                  <span v-else class="frozen" @click="delcompanyGw(item)">激活</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="Pagination">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title="新增专家"
      :visible.sync="dialogVisibleZh"
      width="40%"
      :before-close="closeClickZh"
      center
    >
      <div class="dia_zhuanjia">
        <el-form :model="formZh" ref="refFormZh" :rules="rulesZh" label-width="100px">
          <el-form-item label="专家姓名" prop="specialistName">
            <el-input v-model="formZh.specialistName" placeholder="请输入专家姓名"></el-input>
          </el-form-item>
          <el-form-item label="所属企业" prop="companyFullName">
            <el-select
              v-model="formZh.companyFullName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loadingKey"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.companyFullName"
                :value="item.companyFullName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="对接人职位" prop="position">
            <el-input v-model="formZh.position" placeholder="请输入对接人职位"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input v-model="formZh.contactPhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeClickZh">取 消</el-button>
        <el-button type="primary" @click="submitClickZh">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="新增顾问"
      :visible.sync="dialogVisibleGw"
      width="40%"
      :before-close="closeClickGw"
      center
    >
      <div class="dia_zhuanjia">
        <el-form :model="formGw" ref="refFormGw">
          <el-form-item>
            <div class="search_right">
              <el-input placeholder="请输入姓名" v-model="formGw.keyWord" class="input-with-select">
                <el-button slot="append" @click="searchGw">搜索</el-button>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
        <el-table
          :data="userList"
          ref="multipleTableGw"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loadingGw"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @selection-change="handleSelectionChangeGw"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column label="顾问姓名" prop="userName" align="center"></el-table-column>
          <el-table-column label="联系电话" prop="mobile" align="center"></el-table-column>
        </el-table>
        <div class="Pagination">
          <el-pagination
            :current-page="formGw.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="formGw.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userTotal"
            @size-change="handleSizeChangeGw"
            @current-change="handleCurrentChangeGw"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeClickGw">取 消</el-button>
        <el-button type="primary" @click="submitClickGw">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const defaultFormGw = Object.freeze({
  id: '',
  keyWord: '',
  orderByParam: 'DESC',
  orders: '1',
  pageNum: 1,
  pageSize: 10
})
const defaultFormZh = Object.freeze({
  achieveId: '',
  companyFullName: '',
  companyId: '',
  contactPhone: '',
  id: '',
  position: '',
  specialistName: ''
})
const defaultQueryInfo = Object.freeze({
  id: '',
  keyWord: '',
  orderByParam: 'DESC',
  orders: '1',
  pageNum: 1,
  pageSize: 10
})
import {
  selectArchivesContent,
  getNum,
  selectArchivesUser,
  selectArchivesSpecialist,
  selectArchivesApiUser,
  selectByCompanyFullName,
  insertArchivesSpecialist,
  selectArchivesUserPage,
  insertConsultant,
  updateArchivesSpecialist,
  selectArchiveSpecialist,
  deleteArchivesSpecialist,
  deleteArchivesUser
} from '@/api/archives.js'
export default {
  name: 'customerResponse',
  data() {
    return {
      dialogVisibleGw: false,
      options: [], //企业全称
      loadingKey: false,
      rulesZh: {
        specialistName: [{ required: true, message: '请输入专家', trigger: 'blur' }],
        companyFullName: [{ required: true, message: '请选择企业全称', trigger: 'change' }],
        position: [{ required: true, message: '请输入对接人职位', trigger: 'blur' }],
        contactPhone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
      },
      formZh: { ...defaultFormZh },
      indexType: 1,
      typeList: [
        {
          id: 1,
          name: '客户回复',
          num: 0
        },
        {
          id: 2,
          name: '专家信息',
          num: 0
        },
        {
          id: 3,
          name: '顾问信息',
          num: 0
        }
      ],
      ids: [], //多选
      listData: [], //表格数据
      total: 0,
      loading: false,
      queryInfo: { ...defaultQueryInfo },
      companyinfo: {},
      formGw: { ...defaultFormGw },
      dialogVisibleZh: false,
      userList: [], //新增顾问的列表
      userTotal: 0,
      loadingGw: false,
      userIds: [] //新增顾问多选的id
    }
  },
  computed: {
    placeholderCode() {
      let code
      if (this.indexType === 1) {
        code = '请输入关键词'
      } else if (this.indexType === 2) {
        code = '请输入专家姓名'
      } else if (this.indexType === 3) {
        code = '请输入顾问姓名'
      }
      return code
    },
    archiveId() {
      return this.$route.query.archiveId
    },
    companyId() {
      return this.$route.query.companyId
    },
    countClass() {
      let className
      return (val) => {
        switch (val) {
          case '绑定建群':
            className = 'class_name1'
            break
          case '企业调研':
            className = 'class_name2'
            break
          case '小程序绑定':
            className = 'class_name3'
            break
          case '是否会员':
            className = 'class_name4'
            break
          case '是否专家服务':
            className = 'class_name5'
            break
          case '是否有明确需求':
            className = 'class_name6'
            break
          case '客户冻结':
            className = 'class_name7'
            break
          case '正常运营':
            className = 'class_name8'
            break
          case '会员到期':
            className = 'class_name9'
            break
          case '甄选服务':
            className = 'class_name10'
            break

          default:
            className = ''
            break
        }

        return className
      }
    }
  },
  created() {
    this.getTopData() //获取头部数据
    this.getCount() //获取类型数量
    this.search()
  },
  methods: {
    // 分页
    handleSizeChangeGw(val) {
      this.formGw.pageSize = val
      this.searchGw()
    },
    // 分页
    handleCurrentChangeGw(val) {
      this.formGw.pageNum = val
      this.searchGw()
    },
    // 新增顾问多选
    handleSelectionChangeGw(selection) {
      this.userIds = selection.map((item) => item.id)
    },
    // 新增顾问点击确定
    async submitClickGw() {
      if (this.userIds.length > 0) {
        const query = {
          id: this.archiveId,
          userIds: this.userIds
        }
        const { data: res } = await insertConsultant(query)
        if (res.resultCode === 200) {
          this.$message.success('新增成功')
          this.closeClickGw()
          this.search()
          this.getCount()
        } else {
          this.$message.warning('新增失败')
        }
      } else {
        this.$message.warning('请选择您要新增的顾问')
      }
    },
    // 根据字段搜索系统用户
    async searchGw() {
      this.loadingGw = true
      const { data: res } = await selectArchivesUserPage(this.formGw)
      if (res.resultCode === 200) {
        this.userList = res.data.list
        this.userTotal = res.data.total
        this.loadingGw = false
      } else {
        this.loadingGw = false
      }
    },
    // 顾问点击取消
    closeClickGw() {
      this.$refs.multipleTableGw.clearSelection()
      this.userIds = []
      this.formGw = { ...defaultFormGw }
      this.dialogVisibleGw = false
    },
    // 远程搜索
    async remoteMethod(query) {
      if (query !== '') {
        this.loadingKey = true
        const { data: res } = await selectByCompanyFullName({ companyFullName: query })
        if (res.resultCode === 200) {
          this.loadingKey = false
          this.options = res.data
        }
      } else {
        this.options = []
      }
    },
    // 点击确定
    submitClickZh() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res
          if (this.formZh.id) {
            //编辑
            res = await updateArchivesSpecialist(this.formZh)
          } else {
            // 新增
            res = await insertArchivesSpecialist(this.formZh)
          }
          if (res.data.resultCode === 200) {
            this.$message.success(`${this.formZh.id ? '编辑' : '新增'}成功`)
            this.closeClickZh()
            this.search()
            this.getCount()
          } else {
            this.$message.success(`${this.formZh.id ? '编辑' : '新增'}失败`)
          }
        } else {
          this.$message.warning('请完善表单')
        }
      })
    },
    // 取消关闭弹窗
    closeClickZh() {
      this.dialogVisibleZh = false
      this.formZh = { ...defaultFormZh }
      this.$refs.refFormZh.resetFields()
    },
    // 新增专家 -- 弹窗
    addExpert() {
      this.dialogVisibleZh = true
    },
    // 新增顾问 -- 弹窗
    addConsultant() {
      this.searchGw().then(() => {
        this.dialogVisibleGw = true
      })
    },
    // 顾问点击 冻结解冻
    delcompanyGw(row) {
      const query = {
        id: row.id,
        freezeValue: false
      }
      if (row.notFreeze) {
        query.freezeValue = false
      } else {
        query.freezeValue = true
      }
      this.$alert(`您是否确认${row.notFreeze ? '冻结' : '激活'}顾问？`)
        .then(function () {
          return deleteArchivesUser(query)
        })
        .then(() => {
          this.search()
          this.getCount()
          this.$message.success(`顾问${row.notFreeze ? '冻结' : '激活'}成功`)
        })
        .catch(() => {})
    },
    // 点击编辑
    async editClick(row) {
      const { data: res } = await selectArchiveSpecialist({ id: row.id })
      if (res.resultCode === 200) {
        this.formZh = res.data
        this.dialogVisibleZh = true
      }
    },
    // 点击删除 冻结解冻  专家
    delcompanyZh(row) {
      const query = {
        id: row.id,
        freezeValue: false
      }
      if (row.notFreeze) {
        query.freezeValue = false
      } else {
        query.freezeValue = true
      }
      this.$alert(`您是否确认${row.notFreeze ? '冻结' : '激活'}专家？`)
        .then(function () {
          return deleteArchivesSpecialist(query)
        })
        .then(() => {
          this.search()
          this.getCount()
          this.$message.success(`专家${row.notFreeze ? '冻结' : '激活'}成功`)
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    },
    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.foreignKey)
    },
    //类型选择
    storeClick(id) {
      this.indexType = id
      this.search()
    },
    // 获取表格数据  根据typeIndex
    async search() {
      this.loading = true
      this.queryInfo.id = this.archiveId
      let res
      if (this.indexType === 1) {
        // 客户回复接口
        res = await selectArchivesApiUser(this.queryInfo)
      } else if (this.indexType === 2) {
        // 专家信息
        res = await selectArchivesSpecialist(this.queryInfo)
      } else if (this.indexType === 3) {
        // 顾问信息
        res = await selectArchivesUser(this.queryInfo)
      }
      if (res.data.resultCode === 200 && res.data.data) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      } else {
        this.listData = []
        this.total = 0
        this.loading = false
      }
    },
    // 返回上一层
    goRouter() {
      this.$router.go(-1)
    },
    // 获取数量
    async getCount() {
      const { data: res } = await getNum({ id: this.archiveId })
      if (res.resultCode === 200) {
        this.typeList[0].num = res.data.userReplyNum
        this.typeList[1].num = res.data.specialistNum
        this.typeList[2].num = res.data.consultantNum
      }
    },
    // 获取头部数据
    async getTopData() {
      const { data: res } = await selectArchivesContent({ companyId: this.companyId })
      if (res.resultCode === 200) {
        this.companyinfo = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.customer_response {
  width: 100%;

  .frozen {
    color: #fd5469;
    cursor: pointer;
  }

  .search_right {
    width: 100%;
    margin-right: 40px;
    ::v-deep .el-input-group__append {
      border-right: 0;
    }
    ::v-deep .el-button {
      border: #448aff 1px solid;
      color: #fff;
      background-color: #448aff;
    }
  }

  .dia_zhuanjia {
    width: 100%;
    overflow: hidden;

    ::v-deep .el-select {
      width: 100%;
    }
  }

  .form_info {
    padding: 0px 0px 10px 0px;
    background: #fff;
    margin-top: 20px;

    .form_title {
      width: 100%;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      padding: 24px;
      background-color: #fff;
      justify-content: space-between;
      align-items: center;

      .search_left {
        width: 500px;
        margin-right: 40px;
        ::v-deep .el-input-group__append {
          border-right: 0;
        }
        ::v-deep .el-button {
          border: #448aff 1px solid;
          color: #fff;
          background-color: #448aff;
        }
      }

      .form_title_right {
        display: flex;
        align-items: center;
        color: #969495;
        font-size: 14px;
        font-weight: 400;

        i {
          color: #4e93fb;
          font-size: 16px;
          margin-right: 4px;
        }

        em {
          color: #4e93fb;
          font-weight: 400;
          font-style: normal;
        }
      }
    }

    .tab_txt {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 24px;
    }

    .form {
      padding-top: 15px;
    }
    .store_table {
      padding-left: 10px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      background: #fafafa;

      .store_table_item {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 30px;
        border-radius: 1px;
      }

      .active {
        padding: 0px 10px;
        color: #4e93fb;
        font-size: 16px;
        margin-right: 30px;
        border-radius: 1px;
        background: #ffffff;
      }
    }
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
    .shareResultList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .tooltip_box {
      padding: 0px 5px;
      display: inline-block;
    }
    .spreadwrap {
      -webkit-line-clamp: 2; //设置几行
      display: -webkit-box; //设置为伸缩盒弹性盒子展示
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //设置超出部分以省略号展示
      -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
    }
    .span_tag {
      color: #4e93fb;
      cursor: pointer;
    }
    .input-with {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-left: 20px;
      .input-with-select {
        width: 400px;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }

  .buttom_info {
    margin-left: 12px;
  }
  .tag_item {
    flex: 1;
    margin-left: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .tag_item_div {
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      padding: 3px 8px;
      color: #999999;
      background-color: rgba(153, 153, 153, 0.1);
      margin-right: 8px;
    }

    .class_name1 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
    .class_name2 {
      color: rgba(238, 113, 241, 1);
      background-color: rgba(238, 113, 241, 0.1);
    }
    .class_name3 {
      color: rgba(9, 215, 228, 1);
      background-color: rgba(9, 215, 228, 0.1);
    }
    .class_name4 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name5 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name6 {
      color: rgba(231, 158, 17, 1);
      background-color: rgba(231, 158, 17, 0.1);
    }
    .class_name7 {
      color: rgba(153, 153, 153, 1);
      background-color: rgba(153, 153, 153, 0.1);
    }
    .class_name8 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
    .class_name9 {
      color: rgba(153, 153, 153, 1);
      background-color: rgba(153, 153, 153, 0.1);
    }
    .class_name10 {
      color: rgba(33, 122, 247, 1);
      background-color: rgba(224, 237, 255, 1);
    }
  }

  .order_top {
    background: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .primary_buttom {
      margin-left: 12px;
      border: 1px solid #448aff;
      background: #fff;
    }

    .storeCenter_companyinfo_top_left {
      display: flex;
      flex-direction: row;

      img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        margin-right: 14px;
      }

      .storeCenter_companyinfo_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_companyinfo_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          position: relative;
          left: -5px;

          span {
            box-sizing: border-box;
            padding: 1px 6px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            cursor: pointer;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .address {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
